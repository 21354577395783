import React from 'react';

function Footer() {
	return (
		<footer>
			<p>
				&copy; Designed and Developed by{' '}
				<a href="https://orbititsolutions.ca" target="_blank" rel="noreferrer" className="link">
					OrbitIt Solutions Ltd.
				</a>
			</p>
		</footer>
	);
}

export default Footer;
