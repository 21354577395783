import { Link } from 'react-router-dom';

function GameIntro() {
	return (
		<div className="container">
			<div className="game-intro">
				<div className="game-intro__header">
					<h1 className="game-intro__primary-heading">COVID Aware</h1>
					<h2 className="game-intro__secondary-heading">A COVID-19 awareness-building game</h2>
				</div>
				<div className="game-intro__main">
					<p className="game-intro__description">
						Test your knowledge of Ontario's COVID-19 restrictions and safety measures. These rules
						and regulations depend on the zone or level your region is in. There are five zones -
						green, yellow, orange, red, and grey - with green being the least severe, and grey being
						the most severe. The zones are no longer in effect. For the most up-to-date COVID-19
						information and resources, please visit{' '}
						<a
							href="https://www.ontario.ca/page/covid-19-coronavirus"
							className="link"
							target="_blank"
							rel="noreferrer"
						>
							Ontario COVID-19 web page
						</a>
					</p>
					<div className="btn-container">
						<Link to="/game" className="btn">
							Play
						</Link>
					</div>
				</div>
			</div>
		</div>
	);
}

export default GameIntro;
