const questions = [
	{
		_id: '1',
		text: 'In which zone the indoor limit for parties is 10 people?',
		answers: [
			{ _id: '1', name: 'Green' },
			{ _id: '2', name: 'Yellow' },
			{ _id: '3', name: 'Orange' },
		],
	},
	{
		_id: '2',
		text: 'In which zone the outdoor limit for parties is 25 people?',
		answers: [
			{ _id: '1', name: 'Green' },
			{ _id: '2', name: 'Yellow' },
			{ _id: '3', name: 'Orange' },
			{ _id: '4', name: 'Red' },
		],
	},
	{
		_id: '3',
		text: 'In which zone a maximum of 6 people can be seated together at a restaurant?',
		answers: [{ _id: '2', name: 'Yellow' }],
	},
	{
		_id: '4',
		text: 'In which zone a maximum of 4 people can be seated together at a restaurant?',
		answers: [
			{ _id: '3', name: 'Orange' },
			{ _id: '4', name: 'Red' },
		],
	},
	{
		_id: '5',
		text: 'In which zone the indoor limit for parties is 5 people?',
		answers: [{ _id: '4', name: 'Red' }],
	},
	{
		_id: '6',
		text: 'In which zone the maximum number of patrons permitted to be seated indoors at a restaurant is 10 people?',
		answers: [{ _id: '4', name: 'Red' }],
	},
	{
		_id: '7',
		text: 'In which zone restaurants and similar establishments must remain closed from 10 PM to 5 AM?',
		answers: [
			{ _id: '3', name: 'Orange' },
			{ _id: '4', name: 'Red' },
		],
	},
	{
		_id: '8',
		text: 'In which zone performing arts facilities must remain closed to spectators?',
		answers: [{ _id: '4', name: 'Red' }],
	},
	{
		_id: '9',
		text: 'In which zone indoor organized public events and social gatherings are not allowed, except with members of the same household?',
		answers: [{ _id: '5', name: 'Grey' }],
	},
	{
		_id: '10',
		text: 'In which zone indoor and outdoor services are prohibited at restaurants?',
		answers: [{ _id: '5', name: 'Grey' }],
	},
	{
		_id: '11',
		text: 'In which zone hair salons must remain closed?',
		answers: [{ _id: '5', name: 'Grey' }],
	},
	{
		_id: '12',
		text: 'In which zone casinos must remain closed?',
		answers: [{ _id: '5', name: 'Grey' }],
	},
	{
		_id: '13',
		text: 'In which zone only horse-racing training is allowed and no races?',
		answers: [{ _id: '5', name: 'Grey' }],
	},
	{
		_id: '14',
		text: 'In which zone motorsport events are not allowed?',
		answers: [{ _id: '5', name: 'Grey' }],
	},
	{
		_id: '15',
		text: 'In which zone the indoor limit for public events and gatherings in organizations is 50 people?',
		answers: [
			{ _id: '1', name: 'Green' },
			{ _id: '2', name: 'Yellow' },
			{ _id: '3', name: 'Orange' },
		],
	},
	{
		_id: '16',
		text: 'In which zone the outdoor limit for public events and gatherings in organizations is 100 people?',
		answers: [
			{ _id: '1', name: 'Green' },
			{ _id: '2', name: 'Yellow' },
			{ _id: '3', name: 'Orange' },
		],
	},
	{
		_id: '17',
		text: 'In which zone the outdoor limit for all public events and gatherings is 25 people?',
		answers: [{ _id: '4', name: 'Red' }],
	},
	{
		_id: '18',
		text: 'In which zone the outdoor limit for all public events and gatherings is 10 people?',
		answers: [{ _id: '5', name: 'Grey' }],
	},
	{
		_id: '19',
		text: 'In which zone the outdoor limit for religious gatherings, funerals, and wedding events is 100 people?',
		answers: [
			{ _id: '1', name: 'Green' },
			{ _id: '2', name: 'Yellow' },
			{ _id: '3', name: 'Orange' },
			{ _id: '4', name: 'Red' },
		],
	},
	{
		_id: '20',
		text: 'In which zone the indoor limit for religious gatherings, funerals, and wedding events is 30% capacity of the room?',
		answers: [
			{ _id: '1', name: 'Green' },
			{ _id: '2', name: 'Yellow' },
			{ _id: '3', name: 'Orange' },
			{ _id: '4', name: 'Red' },
		],
	},
	{
		_id: '21',
		text: 'In which zone the outdoor limit for religious gatherings, funerals, and wedding events is 10 people?',
		answers: [{ _id: '5', name: 'Grey' }],
	},
	{
		_id: '22',
		text: 'In which zone the indoor limit for religious gatherings, funerals, and wedding events is 100 people?',
		answers: [{ _id: '5', name: 'Grey' }],
	},
	{
		_id: '23',
		text: 'In which zone no buffet style services in restaurants and bars are allowed?',
		answers: [
			{ _id: '1', name: 'Green' },
			{ _id: '2', name: 'Yellow' },
			{ _id: '3', name: 'Orange' },
			{ _id: '4', name: 'Red' },
		],
	},
	{
		_id: '24',
		text: 'In which zone face coverings are required except when eating or drinking?',
		answers: [
			{ _id: '1', name: 'Green' },
			{ _id: '2', name: 'Yellow' },
			{ _id: '3', name: 'Orange' },
			{ _id: '4', name: 'Red' },
		],
	},
	{
		_id: '25',
		text: 'In which zone night clubs are only permitted to operate as a restaurant or bar?',
		answers: [
			{ _id: '1', name: 'Green' },
			{ _id: '2', name: 'Yellow' },
			{ _id: '3', name: 'Orange' },
			{ _id: '4', name: 'Red' },
		],
	},
	{
		_id: '26',
		text: 'In which zone establishments must remain closed from 12 AM to 5 AM?',
		answers: [{ _id: '2', name: 'Yellow' }],
	},
	{
		_id: '27',
		text: 'In which zone 2 metre physical distancing must be maintained, unless engaged in a sport?',
		answers: [
			{ _id: '1', name: 'Green' },
			{ _id: '2', name: 'Yellow' },
			{ _id: '3', name: 'Orange' },
		],
	},
	{
		_id: '28',
		text: 'In which zone the indoor fitness class limit for sports and fitness facilities is 50 people?',
		answers: [{ _id: '1', name: 'Green' }],
	},
	{
		_id: '29',
		text: 'In which zone the outdoor fitness class limit for sports and fitness facilities is 100 people?',
		answers: [{ _id: '1', name: 'Green' }],
	},
	{
		_id: '30',
		text: 'In which zone the indoor fitness class limit for sports and fitness facilities is 10 people per room?',
		answers: [
			{ _id: '2', name: 'Yellow' },
			{ _id: '3', name: 'Orange' },
		],
	},
	{
		_id: '31',
		text: 'In which zone the outdoor fitness class limit for sports and fitness facilities is 25 people?',
		answers: [
			{ _id: '2', name: 'Yellow' },
			{ _id: '3', name: 'Orange' },
		],
	},
	{
		_id: '32',
		text: 'In which zone the indoor limit for areas with weights or exercise equipment is 50 people?',
		answers: [{ _id: '4', name: 'Red' }],
	},
	{
		_id: '33',
		text: 'In which zone a maximum of 50 people can be present in areas with weights and exercise machines and in all classes?',
		answers: [{ _id: '4', name: 'Red' }],
	},
	{
		_id: '34',
		text: 'In which zone the indoor limit for sports spectators is 50?',
		answers: [{ _id: '4', name: 'Red' }],
	},
	{
		_id: '35',
		text: 'In which zone the outdoor limit for sports spectators is 100?',
		answers: [{ _id: '4', name: 'Red' }],
	},
	{
		_id: '36',
		text: 'In which zone no spectators are permitted except for parents and guardians of children?',
		answers: [{ _id: '4', name: 'Red' }],
	},
	{
		_id: '37',
		text: 'In which zone face coverings are required except when exercising in sports and fitness facilities?',
		answers: [
			{ _id: '1', name: 'Green' },
			{ _id: '2', name: 'Yellow' },
			{ _id: '3', name: 'Orange' },
			{ _id: '4', name: 'Red' },
		],
	},
	{
		_id: '38',
		text: 'In which zone oxygen bars, steam rooms, and saunas must remain closed?',
		answers: [
			{ _id: '1', name: 'Green' },
			{ _id: '2', name: 'Yellow' },
			{ _id: '3', name: 'Orange' },
			{ _id: '4', name: 'Red' },
			{ _id: '5', name: 'Grey' },
		],
	},
	{
		_id: '39',
		text: 'In which zone a safety plan for malls is required to be prepared and made available upon request?',
		answers: [
			{ _id: '2', name: 'Yellow' },
			{ _id: '3', name: 'Orange' },
			{ _id: '4', name: 'Red' },
		],
	},
	{
		_id: '40',
		text: 'In which zone the limit for casinos, bingo halls, and gaming establishments is 50 people?',
		answers: [
			{ _id: '1', name: 'Green' },
			{ _id: '2', name: 'Yellow' },
			{ _id: '3', name: 'Orange' },
		],
	},
	{
		_id: '41',
		text: 'In which zone the indoor limit for casinos, bingo halls, and gaming establishments is 10 people?',
		answers: [{ _id: '4', name: 'Red' }],
	},
	{
		_id: '42',
		text: 'In which zone the outdoor limit for casinos, bingo halls, and gaming establishments is 25 people?',
		answers: [{ _id: '4', name: 'Red' }],
	},
	{
		_id: '43',
		text: 'In which zone table games are prohibited in casinos, bingo halls, and gaming establishments?',
		answers: [
			{ _id: '1', name: 'Green' },
			{ _id: '2', name: 'Yellow' },
			{ _id: '3', name: 'Orange' },
			{ _id: '4', name: 'Red' },
		],
	},
	{
		_id: '44',
		text: 'In which zone cinemas must remain closed except for drive-in cinemas, rehearsals, or when performing broadcasted events with restrictions?',
		answers: [
			{ _id: '4', name: 'Red' },
			{ _id: '5', name: 'Grey' },
		],
	},
	{
		_id: '45',
		text: 'In which zone a safety plan for cinemas is required?',
		answers: [
			{ _id: '2', name: 'Yellow' },
			{ _id: '3', name: 'Orange' },
			{ _id: '4', name: 'Red' },
			{ _id: '5', name: 'Grey' },
		],
	},
	{
		_id: '46',
		text: 'In which zone the indoor spectator limit for performing arts facilities is 50?',
		answers: [
			{ _id: '1', name: 'Green' },
			{ _id: '2', name: 'Yellow' },
			{ _id: '3', name: 'Orange' },
		],
	},
	{
		_id: '47',
		text: 'In which zone the outdoor spectator limit for performing arts facilities is 100?',
		answers: [
			{ _id: '1', name: 'Green' },
			{ _id: '2', name: 'Yellow' },
			{ _id: '3', name: 'Orange' },
		],
	},
	{
		_id: '48',
		text: 'In which zone performing arts facilities must remain closed to spectators?',
		answers: [
			{ _id: '4', name: 'Red' },
			{ _id: '5', name: 'Grey' },
		],
	},
	{
		_id: '49',
		text: 'In which zone drive-in performances are permitted?',
		answers: [
			{ _id: '1', name: 'Green' },
			{ _id: '2', name: 'Yellow' },
			{ _id: '3', name: 'Orange' },
			{ _id: '4', name: 'Red' },
			{ _id: '5', name: 'Grey' },
		],
	},
	{
		_id: '50',
		text: 'In which zone a safety plan is required for performing arts facilities?',
		answers: [
			{ _id: '2', name: 'Yellow' },
			{ _id: '3', name: 'Orange' },
			{ _id: '4', name: 'Red' },
			{ _id: '5', name: 'Grey' },
		],
	},
	{
		_id: '51',
		text: 'In which zone amusement parks and water parks must be closed?',
		answers: [{ _id: '5', name: 'Grey' }],
	},
	{
		_id: '52',
		text: 'In which zone in-person driving instruction sessions are not permitted?',
		answers: [{ _id: '5', name: 'Grey' }],
	},
	{
		_id: '53',
		text: 'In which zone tour and guide services must be closed?',
		answers: [{ _id: '5', name: 'Grey' }],
	},
	{
		_id: '54',
		text: 'In which zone museum and other cultural amenities must be closed (indoors) to the public?',
		answers: [{ _id: '5', name: 'Grey' }],
	},
	{
		_id: '55',
		text: 'In which zone zoos and aquariums must be closed (indoors) to the public?',
		answers: [{ _id: '5', name: 'Grey' }],
	},
];

// This function will shuffle the questions array and return the first 10 questions from the shuffled array
// Using the modern version of the Fisher-Yates shuffle algorithm
export function getRandomQuestions() {
	let shuffledQuestions = [...questions];
	const totalQuestions = shuffledQuestions.length;
	// loop starting from the end of the array
	for (let i = totalQuestions - 1; i > 0; i--) {
		// generating a random number between 0 and i
		// will use this number as index to select a random question from the array
		const j = Math.floor(Math.random() * (i + 1));
		// swapping the position of the questions
		[shuffledQuestions[i], shuffledQuestions[j]] = [shuffledQuestions[j], shuffledQuestions[i]];
	}
	// returning the first 10 questions from the array
	return shuffledQuestions.slice(0, 10).map((question, index) => {
		// adding a new property, number
		return { ...question, number: index + 1 };
	});
}
